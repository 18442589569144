import React, {useState} from 'react'
import {Button, Div, Icon, Input, Text} from 'atomize'
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const FormKyc = (props) => {
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors, isSubmitted, isValid },
    } = useForm();

    const notifySuccess = () => toast.success('Le KYC a bien été envoyé !', {
        className: 'success-toast'
    });
    const notifyWalletError = () => toast.error("Pas de wallet crypto trouvé. Veuillez en installer un.");
    const [isLoading, setLoading] = useState(false);
    const [post, setPost] = useState('');
    const navigate = useNavigate();
    const [country, setCountry] = watch(["country", JSON.stringify({value: 'FR', label: 'France'})])
    const signMessage = async () => {
        try {
            if (!window.ethereum) {
                notifyWalletError()
            }
            setLoading(true)
            const signer = props.library.getSigner();
            return await signer.signMessage(props.account);
        } catch (err) {
            toast.error(err.message, {
                className: 'error-toast'
            });
        }
    };
    const options = countryList().getData();
    const onSubmit = async (evt) => {
        const formData = new FormData();
        formData.append('firstname', evt.firstName)
        formData.append('lastname', evt.lastName)
        formData.append('exp_date', evt.expDate)
        formData.append('country', evt.country.label)
        formData.append('address', props.account)
        formData.append('front_id', evt.fileFront[0])
        formData.append('back_id', evt.fileBack[0])
        const sig = await signMessage();
        if (sig)
            formData.append('signature', sig)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            setLoading(true)
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/kyc`, formData, config)
                .then(response => {
                    setPost(response.data)
                    setLoading(false)
                    props.setAddress(props.account)
                    notifySuccess()
                }).catch((e) => {
                    setPost(e.response.data)
                    toast.error(e.response.data.message);
                    setLoading(false)
                });
        }catch (e){
            setPost(e.response.data)
            toast.error(e.response.data.message);
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {(!isValid && isSubmitted) && Object.keys(errors).length > 0 ?
                <Div bg={'danger700'} textColor={'white'} p={'1.5em'} w={"70%"} rounded={"lg"} textWeight={"700"} d={"flex"} flexDir={"column"}>
                    {Object.values(errors).map( (e,idx) => {
                        return (<Div key={idx}><li>{e.message}</li></Div>)
                    })}
                </Div>
                :
                <></>
            }
            <Div d="flex" flexDir="row" justify="center" flexWrap="wrap">
                <Div d="flex" flexDir="column" flexGrow="4" m={{r: {md: '2rem'}, b: {xs: '1rem'}}}
                     minW="70%">
                    <Div m={{b: '1.15rem'}}>
                        <Div d="flex" flexDir="row" align="center" border={{b: '1px solid'}} w="100%" borderColor="progressBg" m={{b: '2rem'}}>
                            <Text tag="h4" textSize="body" m="1rem" textColor="textColorPrimary">Informations générales</Text>
                        </Div>
                        <Div>
                            <Input
                                id="lastName"
                                name="lastName"
                                placeholder="Nom"
                                border="none"
                                textWeight="700"
                                textSize="subheader"
                                bg="transparent"
                                textColor="textColorPrimary"
                                m={{b: '2rem'}}
                                {...register("lastName", {
                                    required: {
                                        value: true,
                                        message: "Ton nom ne peut pas être vide"
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Ton nom doit faire plus que 2 caractères"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "Ton nom doit faire moins que 100 caractères"
                                    },
                                    pattern:{
                                        value: /^[a-zA-Z]+$/,
                                        message: "Ton nom doit être composé uniquement de lettres"
                                    }
                                })}
                            >
                            </Input>
                        </Div>
                        <Div>
                            <Input
                                id="firstName"
                                name="firstName"
                                placeholder="Prénom"
                                border="none"
                                textWeight="700"
                                textSize="subheader"
                                bg="transparent"
                                textColor="textColorPrimary"
                                m={{b: '2rem'}}
                                {...register("firstName", {
                                    required: {
                                        value: true,
                                        message: "Ton prénom ne peut pas être vide"
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Ton prénom doit faire plus que 2 caractères"
                                    },
                                    maxLength: {
                                            value: 100,
                                            message: "Ton prénom doit faire moins que 100 caractères"
                                    },
                                    pattern:{
                                            value: /^[a-zA-Z]+$/,
                                            message: "Ton prénom doit être composé uniquement de lettres"
                                    }
                                })}
                            >
                            </Input>
                        </Div>
                    </Div>
                    <Div d="flex" flexDir="column" h={'500px'}>
                        <Div d="flex" flexDir="row" align="center" border={{b: '1px solid'}} w="100%" borderColor="progressBg" m={{b: '2rem'}}>
                            <Text tag="h4" textSize="body" m="1rem" textColor="textColorPrimary">KYC & Nationalité</Text>
                        </Div>
                        <Div id="countryFlag" d={'flex'} w={'70%'} align={'center'}>
                            <ReactCountryFlag
                                countryCode={
                                    country ? country.value : ""
                                }
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                                title={country ? country.value : ""}

                            />
                            <Div
                                style={{ marginLeft: "10px", color: "darkColor", width: "100%" }}
                            >
                                <Controller
                                    name="country"
                                    control={control}
                                    rules={{ required: {value: true, message: 'Tu dois sélectionner ton pays'} }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                isSearchable={true}
                                                name="country"
                                                options={options}
                                                value={options.value}
                                                ref={field.ref}
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </Div>
                        </Div>
                        <Div d={'flex'} flexDir={"column"} justify={'center'} m={{t: '2rem'}} p={{l: '12px'}}>
                            <label style={{color: 'white', marginLeft: '10px', marginBottom: '5px'}} htmlFor={'expDate'}>Recto pièce d'identité (Format: png, jpg, jpeg | Taille max: 10mo)</label>
                            <Input textColor={'white'} type={'file'} bg={'none'} border={'none'} accept={'.png, .jpg, .jpeg'}
                                   {...register("fileFront", {
                                       required: {
                                           value: true,
                                           message: "Recto de la pièce d'identité manquant"
                                       }})}/>
                        </Div>
                        <Div d={'flex'} flexDir={"column"} justify={'center'} m={{t: '2rem'}} p={{l: '12px'}}>
                            <label style={{color: 'white', marginLeft: '10px', marginBottom: '5px'}} htmlFor={'fileBack'}>Verso pièce d'identité (Format: png, jpg, jpeg | Taille max: 10mo)</label>
                            <Input id={'fileBack'} textColor={'white'} type={'file'} bg={'none'} border={'none'} accept={'.png, .jpg, .jpeg'}
                                   {...register("fileBack", {
                                       required: {
                                           value: true,
                                           message: "Verso de la pièce d'identité manquant"
                                       }})}/>
                        </Div>
                        <Div d={'flex'} flexDir={"column"} justify={'center'} m={{t: '2rem'}} p={{l: '22px'}}>
                            <label style={{color: 'white', marginBottom: '5px'}} htmlFor={'expDate'}>Date d'expiration de la pièce d'identité</label>
                            <Input type={'date'}
                                   id={'expDate'}
                                   {...register("expDate", {
                                        required: {
                                            value: true,
                                            message: "Date d'expiration de la pièce d'identité manquant"
                                        }})}/>
                        </Div>
                    </Div>
                </Div>
                <Div d="flex" flexDir="column" flexGrow="1.5">
                    <Div d="flex" flexDir="column" justify="center" align="center" m={{b: '1rem'}} p="1rem">
                        <Button bg="white" rounded="circle"
                                m=".5rem" textColor="darkColor" w="70%"
                                transition="false"
                                textWeight="500"
                                disabled={isLoading}
                                onClick={() => {
                                    navigate("/")
                                }}
                        >
                            Retour
                        </Button>
                        <Button bg="lightColor"
                                rounded="circle"
                                m=".5rem" w="70%"
                                textWeight="500"
                                textColor={'darkColor'}
                                transition="false"
                                type="submit"
                                disabled={!props.account || isLoading}
                        >
                            {isLoading ?
                                <Icon
                                    name="Loading"
                                    size="25px"
                                    color="darkColor"
                                /> : 'Envoyer'
                            }
                        </Button>

                        <Text tag="em" textSize="caption" textAlign="center" textColor="textColorPrimary">
                            Votre signature ne sera pas enregistrée.
                        </Text>
                    </Div>
                </Div>
            </Div>
        </form>
    )
}

export default FormKyc