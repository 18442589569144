import { ethers } from 'ethers'
const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;

export const FAST_INTERVAL = 10000;
export const SLOW_INTERVAL = 60000;

export const GAS_PRICE = { 1: '30', 4: '5' };

export const BLOCK_EXPLORER = {
    1: 'https://etherscan.io',
    5: 'https://goerli.etherscan.io'
}

export const INFURA_NETWORK_URLS = {
    1: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    5: `https://goerli.infura.io/v3/${INFURA_KEY}`
}