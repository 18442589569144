// CUSTOM MODAL COMPONENT
import React, {useEffect, useRef} from 'react'
import { CSSTransition } from 'react-transition-group'
import { Div } from 'atomize'

const duration = 300;

const defaultBackLayerStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
};

const backLayerTransitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 0.4 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

const defaultModalContentStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    overflowY: 'auto',
    overflowX: 'hidden'
};

const transitionModalContentStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

const baseProps = {
    m: { y: { xs: '1rem', lg: '2rem' }, x: { xs: '1rem', lg: 'auto' } },
    p: '2rem',
    maxW: '32rem',
    w: '100%',
    pos: 'relative',
    shadow: '4',
    cursor: 'default',
    align: 'start',
    onClose: () => {}
};

const Modal = ({ children, isOpen, onClose, align, ...props }) => {
    const properties = { ...baseProps, ...props };
    const ref = useRef(null);

    const disableBodyScroll = () => {
        document.querySelector('body').style.overflow = 'hidden';

        const { onEntering } = props;
        if (onEntering) onEntering();
    }

    const enableBodyScroll = () => {
        document.querySelector('body').style.overflow = 'auto';

        const { onExiting } = props;
        if (onExiting) onExiting();
    }

    useEffect(() => {
        return () => {
            document.querySelector('body').style.overflow = 'auto';
        }
    }, []);

    return (
        <CSSTransition
            in={isOpen}
            timeout={duration}
            nodeRef={ref}
            onEntering={disableBodyScroll}
            onExiting={enableBodyScroll}
            unmountOnExit
        >
            {state => (
                <>
                    <Div
                        style={{
                            ...defaultBackLayerStyle,
                            ...backLayerTransitionStyles[state]
                        }}
                        pos="fixed"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="black"
                        opacity="0.4"
                        zIndex="1000"
                    />

                    <Div
                        style={{
                            ...defaultModalContentStyle,
                            ...transitionModalContentStyles[state]
                        }}
                        onClick={onClose}
                        tabIndex="-1"
                        pos="fixed"
                        top="0"
                        left="0"
                        right="0"
                        h="100vh"
                        d="flex"
                        align={align}
                        justify="center"
                        zIndex="1001"
                        cursor="pointer"
                    >
                        <Div
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            bg="white"
                            {...properties}
                            zIndex="1"
                        >
                            {children}
                        </Div>
                    </Div>
                </>
            )}
        </CSSTransition>
    );
}
export default Modal;