import MetamaskIcon from 'components/Icons/MetamaskIcon'
import WalletConnectIcon from 'components/Icons/WalletConnectIcon'
import TrustWalletIcon from 'components/Icons/TrustWalletIcon'
import MathWalletIcon from 'components/Icons/MathWalletIcon'

const connectors = [
    {
        title: 'MetaMask',
        connectorId: 'injected',
        icon: <MetamaskIcon />
    },
    {
        title: 'WalletConnect',
        connectorId: 'walletconnect',
        icon: <WalletConnectIcon />
    },
    {
        title: 'Trust Wallet',
        connectorId: 'injected',
        icon: <TrustWalletIcon />
    },
    {
        title: 'MathWallet',
        connectorId: 'injected',
        icon: <MathWalletIcon />
    }
];

export default connectors;