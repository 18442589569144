import React from 'react'

import MintContainer from '../components/MintContainer'
import { Div, Container, Text } from 'atomize'

const PrivateMint = () => {
    return (
        <Div tag="section" m={{ t: { xs: '8rem', md: '15rem' } }}>
            <Container d="flex" flexDir="column" align="center">
                <Text tag="h1" textWeight="500" textAlign="center" textSize="display2" m={{ b: '1rem' }} textColor="lightColor">
                    Mint réservé aux membres <u>Whitelist</u>
                </Text>

                <Text tag="h2" textWeight="400" maxW="36rem" textAlign="center" textSize="subheader" textColor="lightColor" m={{ b: '3rem' }}>
                    Ils vous permettront d’avoir un accès illimité et à vie à notre projet.
                    Cette supply est faible par choix, nous voulons permettre aux
                    détenteurs de NFT d’avoir un réel avantage face à ceux qui auront
                    un abonnement.
                </Text>

                <MintContainer isPrivateMint={true} />
            </Container>
        </Div>
    );
};

export default PrivateMint;