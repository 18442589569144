import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';

import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { StyleReset, ThemeProvider } from 'atomize';
import { Buffer } from 'buffer';

import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from 'utils/web3'
import { NotificationsProvider } from 'contexts/NotificationsContext'

import { theme } from 'config/constants/theme'

const debug = process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine();
const engine = new Styletron();

window.Buffer = window.Buffer || Buffer;

ReactDOM.render(
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <ThemeProvider theme={theme}>
                <NotificationsProvider>
                    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
                        <StyleReset />
                        <App />
                    </StyletronProvider>
                </NotificationsProvider>
            </ThemeProvider>
        </Web3ReactProvider>
    </React.StrictMode>,
    document.getElementById('root')
);