import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import {
    getMintContract,
    getProviderOrSigner,
    getTokenContract
} from 'utils/contractHelpers'

export const useSeason1MintContract = () => {
    const { account, library } = useActiveWeb3React();
    return useMemo(
        () => ({
            reader: getMintContract(null),
            signer: getMintContract(getProviderOrSigner(library, account)),
        }),
        [account, library],
    );
}

export const useToken = (address, withSignerIfPossible = true) => {
    const { library, account } = useActiveWeb3React();
    return useMemo(
        () => getTokenContract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
        [account, address, library, withSignerIfPossible]
    );
}