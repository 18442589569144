import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import {INITIAL_SUPPLY, MAX_MINT, WHITELIST_SUPPLY} from "../../../config/constants/mint";
import multicall from "../../../utils/multicall";
import erc721abi from 'config/abi/erc721a.json'
import {getSeason1MintAddress} from "../../../utils/addressHelpers";

const useMintState = (quantity) => {
    const { account } = useWeb3React();

    const [mintState, setMintState] = useState({
        privateMint: {
            isActive: false,
            hasAlreadyMinted: false,
            isSoldOut: false
        },
        publicMint: {
            isActive: false,
            hasTooMuchMinted: false,
            isSoldOut: false
        },
        totalSupply: 0,
        supplyLeft: 0
    });

    const contractAddress = getSeason1MintAddress();

    useEffect(() => {
        const fetchMint = async () => {
            const calls = [
                { address: contractAddress, name: 'mintStartTimestamp' },
                { address: contractAddress, name: 'totalSupply' },
                { address: contractAddress, name: 'numberMinted', params: [account] },
                { address: contractAddress, name: 'balanceOf', params: [account] }
            ];

            const results = await multicall(erc721abi, calls);

            const mintStartTime = results[0][0];
            const totalSupply = results[1][0];
            const numberMinted = results[2][0];
            const balanceOf = results[3][0];

            // Determine if private sales is started
            if (mintStartTime.gt(0)) {
                setMintState(prevState => ({
                    ...prevState,
                    privateMint: {
                        ...prevState.privateMint,
                        isActive: true
                    }
                }))
            }

            // Determine if public sales is started
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (mintStartTime.gt(0) && mintStartTime.add(86400).lte(currentTimestamp)) {
                setMintState(prevState => ({
                    ...prevState,
                    publicMint: {
                        ...prevState.publicMint,
                        isActive: true
                    }
                }));
            }

            // We manage state of private sales
            if (mintState.privateMint.isActive) {
                if (totalSupply.add(1).gt(INITIAL_SUPPLY)) {
                    setMintState(prevState => ({
                        ...prevState,
                        privateMint: {
                            ...prevState.privateMint,
                            isSoldOut: true
                        }
                    }));
                }

                if (numberMinted.gt(0)) {
                    setMintState(prevState => ({
                        ...prevState,
                        privateMint: {
                            ...prevState.privateMint,
                            hasAlreadyMinted: true
                        }
                    }));
                }
            }

            // We manage state of public sales
            if (mintState.publicMint.isActive) {
                if (totalSupply.add(quantity).gt(INITIAL_SUPPLY - WHITELIST_SUPPLY)) {
                    setMintState(prevState => ({
                        ...prevState,
                        publicMint: {
                            ...prevState.publicMint,
                            isSoldOut: true
                        }
                    }));
                }

                if (balanceOf.add(quantity).gt(MAX_MINT)) {
                    setMintState(prevState => ({
                        ...prevState,
                        publicMint: {
                            ...prevState.publicMint,
                            hasTooMuchMinted: true
                        }
                    }));
                }
            }

            setMintState(prevState => ({
                ...prevState,
                totalSupply: totalSupply.toNumber(),
                supplyLeft: INITIAL_SUPPLY - totalSupply.toNumber()
            }));
        }

        if (account) fetchMint().then(r => r);
    }, [account]);

    return mintState;
}

export default useMintState;