export const theme = {
    fontFamily: {
        primary: "'Poppins', sans-serif"
    },
    colors: {
        lightColor: '#FFEDDE',
        lightColor700: '#FFDDC2',
        darkColor: '#211F24',
        greenActive: 'rgb(24 155 92)',
        progressBg: '#d0d7de',
        valid: '#23DC3D',
        progressDarker: '#57606a',
        lightDark: 'rgb(0 0 0 / 86%)',
        buttonBg: '#e1e4e826',
        bgColorPrimary: '#211F24',
        textColorPrimary: 'white',
        textColorSecondary: 'hsla(0,0%,100%,.7)',
        borderColorPrimary: 'rgb(4 4 90)'
    },
    shadows: {
        "test": "    inset 0 0 50px #fff,      /* inner white */\n" +
            "    inset 20px 0 80px #f0f,   /* inner left magenta short */\n" +
            "    inset -20px 0 80px #0ff,  /* inner right cyan short */\n" +
            "    inset 20px 0 300px #f0f,  /* inner left magenta broad */\n" +
            "    inset -20px 0 300px #0ff, /* inner right cyan broad */\n" +
            "    0 0 50px #fff,            /* outer white */\n" +
            "    -10px 0 80px #f0f,        /* outer left magenta */\n" +
            "    10px 0 80px #0ff;         /* outer right cyan */",
        "new-shadow": "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        "borderShadow": "0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 2rem #bc13fe, 0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe"
}
};