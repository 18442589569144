import { Contract } from '@ethersproject/contracts'
import { simpleRpcProvider } from 'utils/providers'

import erc20Abi from 'config/abi/erc721.json'
import multiCallAbi from 'config/abi/multicall.json'
import erc721abi from 'config/abi/erc721a.json'

import { getMulticallAddress, getSeason1MintAddress } from './addressHelpers'

// account is not optional
export function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library, account) {
    return account ? getSigner(library, account) : library;
}

export const getContract = (abi, address, signer) => {
    const signerOrProvider = signer ?? simpleRpcProvider;
    return new Contract(address, abi, signerOrProvider);
}

export const getMintContract = (signer) => {
    return getContract(erc721abi, getSeason1MintAddress(), signer);
}

export const getTokenContract = (address, signer) => {
    return getContract(erc20Abi, address, signer);
}

export const getMulticallContract = () => {
    return getContract(multiCallAbi, getMulticallAddress(), simpleRpcProvider);
}