const contracts = {
    mint: {
        1: '0xd7a795da596bb23842505c34bd13eb7e6c3e6eb3',
        5: '0xd5fb78e0357fa699d54f95d45218a1a805a2558f'
    },
    multiCall: {
        1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
        5: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696'
    },
    wrappedEther: {
        1: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        5: '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1' // dummy
    }
}

export default contracts;