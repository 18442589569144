import { TransitionGroup } from 'react-transition-group'
import Notification from './Notification';

const ZINDEX = 500;
const TOP_POSITION = 80;

const NotificationContainer = ({ notifications, onRemove, ttl = 6000, stackSpacing = 24 }) => {
    return (
        <TransitionGroup>
            {notifications.map((notification, index) => {
                const zIndex = (ZINDEX - index).toString();
                const top = TOP_POSITION + index * stackSpacing;

                return <Notification
                    key={index}
                    notification={notification}
                    onRemove={onRemove}
                    ttl={ttl}
                    top={top}
                    zIndex={zIndex}
                />
            })}
        </TransitionGroup>
    );
};

export default NotificationContainer;