import React, { useState } from 'react'
import { MAX_SUPPLY, EVENT_MINT_NAME } from 'config/constants/mint'

import { useWeb3React } from '@web3-react/core'
import useMint from '../hooks/useMint'
import useNotification from 'hooks/useNotification'
import useFetchUserCollection from 'hooks/useFetchUserCollection'

import { Div, Text } from 'atomize'
import MintButton from './MintButton'
import DisabledButton from './DisabledButton'
import ConnectButton from 'components/ConnectButton'
import MintModal from './MintModal';
import Number from 'components/Number'
import useMintProof from "../../../hooks/useMintProof";
import useWhitelistProof from "../../../hooks/useWhitelistProof";
import useMintState from "../hooks/useMintState";
import {toast} from "react-toastify";

const MintContainer = ({ isPrivateMint }) => {
    const { notificationInfo, notificationSuccess, notificationError } = useNotification();
    const { account } = useWeb3React();
    const [minted, setMinted] = useState(false);
    const [mintedTokenIds, setMintedTokenIds] = useState([]);
    const mintProof = useMintProof(isPrivateMint)
    const wlProof = useWhitelistProof(isPrivateMint)
    const mintState = useMintState();

    const { onMint } = useMint();
    const handleMint = async (amount, merkleProof) => {
        await onMint(
            amount,
            merkleProof,
            isPrivateMint,
            (tx) => {
                notificationInfo('Transaction submitted', tx.hash, true);
            },
            (receipt) => {
                const desc = amount > 1 ? 'Vous avez réussi à mint' + amount + ' NFT !' : 'Ton NFT a été mint avec succès !';
                notificationSuccess('Minted !', desc);

                const events = receipt.events.filter(event => event.event === EVENT_MINT_NAME);
                const tokenIds = events.map(event => event.args.tokenId.toString());

                setMinted(true);
                setMintedTokenIds(tokenIds);
            },
            (response) => {
                toast.error(response)
            }
        );
    }

    return (
        <Div d="flex" w="100%" justify="space-evenly" flexDir={{ xs: 'column', md: 'row' }} style={{ columnGap: '1rem' }}>
            <Div d="flex" flexDir="column" align="center">
                {account ?
                    isPrivateMint ?
                        wlProof.success ?
                            mintState.privateMint.isActive ?
                                !mintState.privateMint.hasAlreadyMinted ?
                                    mintState.privateMint.isSoldOut ?
                                        <DisabledButton>Sold Out</DisabledButton> : <MintButton bg="lightColor" hoverBg="lightColor700" merkleProof={wlProof.leaf} onConfirm={handleMint} isPrivateMint={isPrivateMint}/>
                                    : <DisabledButton>Maximum minted</DisabledButton>
                                : <DisabledButton>Mint non ouvert</DisabledButton>
                            : <DisabledButton>Vous n'êtes pas Whitelist</DisabledButton>
                        : mintProof.success ?
                                mintState.publicMint.isActive ?
                                    !mintState.publicMint.hasTooMuchMinted ?
                                        mintState.publicMint.isSoldOut ?
                                            <DisabledButton>Sold Out</DisabledButton> : <MintButton bg="lightColor" hoverBg="lightColor700"  merkleProof={mintProof.leaf} onConfirm={handleMint} isPrivateMint={isPrivateMint}/>
                                        : <DisabledButton>Maximum minted</DisabledButton>
                                    : <DisabledButton>Mint non ouvert</DisabledButton>
                            : <DisabledButton>Vous n'êtes pas KYC</DisabledButton>
                        : <ConnectButton />
                }
                {
                    !isPrivateMint ?
                        <Div d={'flex'} flexDir={'column'} align={'center'} m={'1rem'}>
                            <Text tag="i" textColor="textColorSecondary" textWeight="400">Minted: <Number number={mintState.totalSupply} /></Text>
                            <Text tag="i" textColor="textColorSecondary" textWeight="400">Available: <Number number={mintState.supplyLeft} start={MAX_SUPPLY} /></Text>
                        </Div>
                    : <></>
                }
            </Div>

            <MintModal isOpen={minted} tokenIds={mintedTokenIds} onClose={() => setMinted(false)} />
        </Div>
    );
};

export default MintContainer;