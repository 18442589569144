import React from 'react'
import { Link } from 'react-router-dom'
import { Div, Container, Image } from 'atomize'

import Links from './Links'

import logo from 'assets/images/logo-light.svg'

const Header = ({ children }) => {
    return (
        <>
            <Div tag="header"
                 p={{ y: { xs: '1.5rem', md: '1rem' } }}
                 pos="fixed"
                 top="0"
                 left="0"
                 right="0"
                 zIndex="100"
                 bg="white"
                 shadow="4"
                 bg="bgColorPrimary"
                 transition
            >
                <Container d="flex" align="center" justify="space-between">
                    <Div cursor="pointer">
                        <Link to="/">
                            <Image src={logo} alt="WAGTI Corp logo" w="8em" m={{y: '1em'}} />
                        </Link>
                    </Div>

                    <Links />
                </Container>
            </Div>
            {children}
        </>
    );
};

export default Header;