import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from 'utils/web3'

const useEagerConnect = () => {
    const { activate, active } = useWeb3React();
    const [tried, setTried] = useState(false)

    useEffect(() => {
        const connectorId = window.localStorage.getItem(process.env.REACT_APP_CONNECTOR_STORAGE_KEY);

        if (!active && !tried && connectorId) {
            injected.isAuthorized().then(isAuthorized => {
                if (isAuthorized) {
                    activate(injected, undefined, true).catch(() => {
                        setTried(true);
                    });
                }
            })
        }
    }, []);

    useEffect(() => {
        if (active) {
            setTried(true);
        }
    }, [active])

    return tried;
}

export default useEagerConnect;