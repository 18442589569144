import React from 'react'

const Video = ({ src }) => {
    const onHover = (e) => {
        e.target.play();
    }

    const onLeave = (e) => {
        e.target.pause();
        e.target.currentTime = 0;
    }

    return (
        <video loop muted src={src} onMouseOver={onHover} onMouseOut={onLeave} />
    );
}

export default Video;