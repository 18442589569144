import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import axios from "axios";

const useWhitelistProof = (isPrivateMint) => {
    const { account } = useWeb3React();
    const [whitelistProof, setWhitelistProof] = useState({success: false, leaf: []});

    useEffect(() => {
        const fetchData = async () => {
            try{
                const config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                    mode: 'no-cors'
                }

                await axios.get(`${process.env.REACT_APP_API_URL}/wl-proof/${account}`, config)
                    .then(response => {
                        setWhitelistProof(response.data)
                    }).catch(error => {
                    });
            }catch (e) {

            }
        };

        if (account && isPrivateMint) fetchData();
    }, [account]);

    return whitelistProof;
}

export default useWhitelistProof;