import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import axios from "axios";

const useMintProof = (isPrivateMint) => {
    const { account } = useWeb3React();
    const [mintProof, setMintProof] = useState({success: false, leaf: []});

    useEffect(() => {
        const fetchData = async () => {
            try{
                const config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                    mode: 'no-cors'
                }

                await axios.get(`${process.env.REACT_APP_API_URL}/mint-proof/${account}`, config)
                    .then(response => {
                        setMintProof(response.data)
                    }).catch(error => {
                    });
            }catch (e) {
            }
        };

        if (account && !isPrivateMint) fetchData();
    }, [account]);

    return mintProof;
}

export default useMintProof;