import { useCallback } from 'react'
import { mintAmount } from 'utils/calls'
import { useSeason1MintContract } from 'hooks/useContract'

const useMint = () => {
    const { signer: season1MintContract } = useSeason1MintContract();

    const handleMint = useCallback(
        async (
            amount,
            merkleProof,
            isPrivateMint,
            onTransactionSubmitted,
            onSuccess,
            onError
        ) => {
            const tx = await mintAmount(season1MintContract, amount, isPrivateMint, merkleProof);
            if (tx.error) {
                onError(tx.message);
            } else {
                onTransactionSubmitted(tx);

                const receipt = await tx.wait();
                if (receipt.status) {
                    onSuccess(receipt);
                } else {
                    onError(receipt);
                }
            }
        },
        [season1MintContract]
    );

    return { onMint: handleMint };
}

export default useMint;