import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Div, Container } from 'atomize'
import FormKyc from "./components/FormKyc";
import axios from "axios";
import MiniLoader from "../../components/Loader/MiniLoader";
import StatusKyc from "./components/StatusKyc";

const Kyc = () => {
    const {account, library} = useWeb3React()
    const [address, setAddress] = useState('')
    const [status, setStatus] = useState({ success: false, status: "", message: "", error: "" });
    const [isLoading, setIsLoading] = useState(false);

    const ENUM_STATES = {
        pending: <StatusKyc status={'pending'}>Votre KYC est en cours de traitement... Revenez plus tard.</StatusKyc>,
        approved: <StatusKyc status={'approved'}>Votre KYC a été validé, enjoy !</StatusKyc>,
        default: <FormKyc account={account} library={library} setAddress={setAddress}/>
    };

    const getStatus = async () => {
                try {
                    setIsLoading(true);
                    const config = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                        },
                        mode: 'no-cors'
                    }
                    await axios.get(`${process.env.REACT_APP_API_URL}/check-kyc/${account}`, config)
                        .then(response => {
                            setStatus({
                                success: response.data.success,
                                status: response.data.status,
                                message: response.data.message,
                                error: ''
                            });
                        }).catch(error => {
                            setStatus({
                                success: error.response.data.success,
                                status: error.response.data.status ? error.response.data.status : 'default',
                                message: error.response.data.message,
                                error: ''
                            });
                        }).finally(() => {
                            setIsLoading(false);
                        });
                } catch (e) {
                    setStatus({
                        success: false,
                        status: 'default',
                        message: '',
                        error: ''
                    });
                    setIsLoading(false);
                }
    };

    useEffect(() => {
        if(account) {
            getStatus().then(r => r)
        } else {
            return setStatus({
                success: false,
                status: 'default',
                message: '',
                error: ''
            });
        }
    }, [account, address]);

    function EnumState({ status }) {
        return <>{ENUM_STATES[status.status]}</>;
    }

    return (
        <Div tag="section" m={{t: {xs: '8rem', md: '8rem'}}}>
            <Container d="flex" flexDir="column" justify="center" align={isLoading ? 'center' : ''}>
                {
                    isLoading ?
                        <MiniLoader/> : <EnumState status={status}/>

                }
            </Container>
        </Div>
    )
}

export default Kyc