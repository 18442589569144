import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'

import { Icon, Container } from 'atomize'
import MiniLoader from 'components/Loader/MiniLoader'
import Modal from 'components/Modal'
import Video from 'components/Video'
import { getNftUrl } from 'utils/format'

// TODO: useLoadImage
const MintModal = ({ isOpen, tokenIds, onClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const items = tokenIds.map(tokenId => getNftUrl(tokenId));

    const containerRef = useRef();
    const q = gsap.utils.selector(containerRef);

    useEffect(() => {
        //if (isOpen) gsap.fromTo(q('.nft-image'), { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, stagger: 2, duration: 1 });
        if (isOpen) {
            setTimeout(() => {
                setIsLoading(false);
            }, 2500);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            align="center"
            rounded="xl"
            maxW="48rem"
            bg={'darkColor'}
            onClose={onClose}
        >
            <Icon
                name="Cross"
                pos="absolute"
                top="1rem"
                right="1rem"
                size="1.5rem"
                cursor="pointer"
                onClick={onClose}
            />

            <Container d="flex" flexDir={isLoading ? 'column' : 'row'} align="center" flexWrap="wrap" ref={containerRef} justify="center" style={{ columnGap: '1rem', rowGap: '1rem' }}>
                {isLoading ? (
                    <MiniLoader>Fetching your collection, please wait few seconds...</MiniLoader>
                ) : items.map((item, index) => {
                    return <Video key={index} src={item} w={'40rem'} />;
                })}
            </Container>
        </Modal>
    );
}

export default MintModal;