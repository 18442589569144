import addresses from 'config/constants/contracts'

export const getAddress = (address) => {
    const chainId = process.env.REACT_APP_CHAIN_ID;
    return address[chainId] ? address[chainId] : null;
}

export const getMulticallAddress = () => {
    return getAddress(addresses.multiCall);
}

export const getSeason1MintAddress = () => {
    return getAddress(addresses.mint);
}

export const getWrappedEtherAddress = () => {
    return getAddress(addresses.wrappedEther);
}