import { useState, useEffect } from 'react'
import { useSeason1MintContract } from 'hooks/useContract'
import { useWeb3React } from '@web3-react/core'

const useFetchUserCollection = () => {
    const { account } = useWeb3React();
    const { signer: season1MintContract } = useSeason1MintContract();
    const [tokenIds, setTokenIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCollection = async () => {
            const tokenIds = await season1MintContract.tokensOfOwner(account);
            setTokenIds(tokenIds);
            setIsLoading(false);
        }

        if (account) fetchCollection();
    }, [account, season1MintContract]);

    return { tokenIds, isLoading };
}

export default useFetchUserCollection;