import { Interface } from '@ethersproject/abi'
import { getMulticallContract } from 'utils/contractHelpers'

const multicall = async (abi, calls) => {
    const multi = getMulticallContract();
    const itf = new Interface(abi);

    const calldata = calls.map(call => ({
        target: call.address.toLowerCase(),
        callData: itf.encodeFunctionData(call.name, call.params)
    }));

    // TODO: Set to true
    const returnData = await multi.tryAggregate(false, calldata);
    return returnData.map((call, i) => {
        const [result, data] = call;
        return result ? itf.decodeFunctionResult(calls[i].name, data) : null;
    });
}

export default multicall;