import React, { useState } from 'react'
import { Button, Icon } from 'atomize'
import ConnectModal from "../ConnectModal";

const ConnectButton = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                d="flex"
                bg="lightColor"
                hoverBg="lightColor"
                textColor="darkColor"
                prefix={<Icon name="Link" size="20px" m={{ r: '.3rem' }} color="darkColor" />}
                onClick={() => setShowModal(true)}
            >
                Connect Wallet
            </Button>

            <ConnectModal isOpen={showModal} onClose={() => setShowModal(false)} />
        </>
    );
};

export default ConnectButton;