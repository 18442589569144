import React, {Suspense, lazy, useState} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Div } from 'atomize'

import Header from 'components/Header'
import PageLoader from 'components/Loader/PageLoader'
import { NotificationListener } from 'contexts/NotificationsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import AuthRoute from 'components/AuthRoute'
import '../src/styles.css'
import Kyc from "./views/Kyc";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css"
import KycContext from "./contexts/KycContext";
import PrivateMint from "./views/Mint/PrivateMint";
import PublicMint from "./views/Mint/PublicMint";

const Home = lazy(() => import('views/Home'));
const Account = lazy(() => import('views/Account'));

const App = () => {
    useEagerConnect();
    const [isKyc, setIsKyc] = useState(false)
    return (
        <KycContext.Provider
            value={{isKyc}}>
            <BrowserRouter>
                <Div tag="main" overflow="hidden">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header>
                        <Suspense fallback={<PageLoader />}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/public" element={<PublicMint />} />
                                <Route path="/whitelist" element={<PrivateMint />} />
                                <Route path="/kyc" element={<Kyc />} />
                                {/*<Route path="/account" element={<AuthRoute />}>
                                    <Route path="/account" element={<Account />} />
                                </Route>*/}
                            </Routes>
                        </Suspense>
                    </Header>
                </Div>

                <NotificationListener />
            </BrowserRouter>
        </KycContext.Provider>
    );
}

export default App;
