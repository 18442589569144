import React, {useEffect, useState} from 'react'
import { Container, Icon, Text, Div } from 'atomize'
import Modal from '../Modal'

import connectors from 'config/constants/connectors'
import useAuth from 'hooks/useAuth'

const ConnectModal = ({ isOpen, onClose }) => {
    const { login } = useAuth();
    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} align="center" rounded="md">
            <Icon
                name="Cross"
                pos="absolute"
                top="1rem"
                right="1rem"
                size="16px"
                onClick={onClose}
                cursor="pointer"
            />

            <Container d="flex" flexDir={{xs: 'column', md: 'row'}} justify="space-between">
                {connectors.map((connector, index) => (
                    <Div
                        d="flex"
                        flexDir="column"
                        align="center"
                        cursor="pointer"
                        m={{y: {xs: '1rem', md: '0'}}}
                        hoverTextColor="gray500"
                        onClick={() => login(connector.connectorId)}
                        key={index}
                        transition
                    >
                        {isMobile ?
                            connector.title === 'WalletConnect' ?
                                connector.icon : <></>
                            : connector.icon
                        }
                        <Text>
                            {isMobile ?
                                connector.title === 'WalletConnect' ?
                                    connector.title : <></>
                                : connector.title
                            }
                        </Text>
                    </Div>
                ))}
            </Container>
        </Modal>
    );
};

export default ConnectModal;