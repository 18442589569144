import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'

import { switchNetwork } from 'utils/wallet'
import { connectorsByName } from 'utils/web3'
import useNotification from 'hooks/useNotification'

const useAuth = () => {
    const { activate, deactivate } = useWeb3React();
    const { notificationError } = useNotification();
    const CONNECTOR_STORAGE_KEY = process.env.REACT_APP_CONNECTOR_STORAGE_KEY;

    const login = useCallback(connectorId => {
        const connector = connectorsByName[connectorId];
        if (connector) {
            activate(connector, undefined, true)
                .then(async () => {
                    window.localStorage.setItem(CONNECTOR_STORAGE_KEY, connectorId);
                }).catch(async (error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        const hasNetwork = await switchNetwork();
                        if (hasNetwork) activate(connector);
                    } else {
                        window.localStorage.removeItem(CONNECTOR_STORAGE_KEY);

                        if (error instanceof NoEthereumProviderError) {
                            notificationError('Provider error', 'No provider was found.');
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                connector.walletConnectProvider = null
                            }

                            notificationError('Authorization error', 'Please authorize to access your account.');
                        } else {
                            notificationError(error.name, error.message);
                        }
                    }
                });
        } else {
            notificationError('Unable to find connector', 'The connector configuration is invalid.');
        }
    }, [activate, notificationError]);

    const logout = useCallback(() => {
        deactivate();
        window.localStorage.removeItem(process.env.REACT_APP_CONNECTOR_STORAGE_KEY);
    }, [deactivate])

    return { login, logout };
}

export default useAuth;