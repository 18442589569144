import React, {useEffect, useRef} from 'react'
import { useCountUp } from 'react-countup'

import { Text } from 'atomize'

const Number = ({ number, start, decimals, suffix = '', prefix = '', ...props }) => {
    const countUpRef = useRef(null);

    let properties = {
        ref: countUpRef,
        start,
        end: number,
        separator: ',',
        duration: .8,
        delay: 0,
        decimal: '.',
        decimals,
        prefix,
        suffix
    };

    const { update } = useCountUp(properties);
    useEffect(() => {
        update(number);
    }, [number, update]);

    return (
        <Text d="inline" ref={countUpRef} {...props} />
    );
}

export default Number;