import React from 'react'
import { Button, Text } from 'atomize'

const DisabledButton = ({ children }) => {
    return (
        <Button
            w="100%"
            m={{ b: { xs: '1rem', sm: 0 } }}
            h="3.5rem"
            disabled
        >
            <Text textColor={'darkColor'} textWeight={'600'}>{children}</Text>
        </Button>
    );
};

export default DisabledButton;