import { NFT_EXTENSION } from 'config/constants/mint'

export const formatAddress = (address) => {
    return address.substring(0, 5) + '•••' + address.slice(-4);
};

export const ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const isEmpty = (array) => {
    return array.length === 0;
};

export const getNftUrl = (id) => {
    const ipfsUri = `${process.env.REACT_APP_IPFS_GATEWAY}/${process.env.REACT_APP_IPFS_CID}/`;

    return ipfsUri + id + NFT_EXTENSION;
}

export const getErrorMessage = (message) => {
    const errorType = 'execution reverted:';
    return message[4].replace(errorType, '').trim();
};

export const toTimestamp = (strDate) => {
    const dt = Date.parse(strDate);
    return dt / 1000;
}