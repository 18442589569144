import { Div, Text, Icon } from 'atomize'
import React from 'react'

const StatusKyc = ({ status, children }) => {
    return (
            <Div d="flex" justify="center" align={'center'} bg='lightColor' m={{t: {xs: '2rem', md: '5rem'}}} p={{xs: '2rem', md: '10rem'}} rounded={'xl'} flexDir={{xs: 'column', md: 'row'}}>
                <Div p={'1rem'}>
                    <Icon name={status === 'approved' ? 'Checked' : 'Loading'} size={{xs:'3rem', md:'6rem'}} color={status === 'approved' ? 'valid' : 'darkColor'}/>
                </Div>
                <Div>
                    <Text tag="h1" textWeight="500" textAlign="center" textSize={{xs:"heading", md: "display2"}} m={{ b: '1rem' }} textColor="darkColor">
                        {children}
                    </Text>
                </Div>
            </Div>
    );
}

export default StatusKyc