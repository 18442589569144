import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Div, Text } from 'atomize'

import { useWeb3React } from '@web3-react/core'

import ConnectButton from 'components/ConnectButton'
import WalletButton from 'components/WalletButton'

const AnchorLink = ({ to, text }) => {
    function changeColorText(e) {
        e.target.style.color = 'red';
    }

    return <NavLink to={to} style={{transition: 'all 1s ease-out'}}>
                <Text
                    m={{ r: '2rem', b: { xs: '1rem', md: 0 } }}
                    textWeight="500"
                    hoverTextColor="textColorPrimary"
                    textColor="textColorSecondary"
                    style={{transition: 'all .5s ease-out'}}
                >
                {text}
                </Text>
            </NavLink>
};

const Links = () => {
    const { account } = useWeb3React();
    const [showMobileHeaderMenu, setShowMobileHeaderMenu] = useState(false);

    return (
        <>
            {/* mobile links */}
            <Div d={{ xs: "flex", md: "none" }} flexDir="column" onClick={() => setShowMobileHeaderMenu(!showMobileHeaderMenu)}>
                <Div h="2px" w="1rem" bg="white" rounded="lg" transition style={{
                    transform: `translateY(${showMobileHeaderMenu ? '1' : '-2'}px) rotate(${showMobileHeaderMenu ? '135' : '0'}deg)`
                }} />
                <Div h="2px" w="1rem" bg="white" rounded="lg" transition style={{
                    transform: `translateY(${showMobileHeaderMenu ? '-1' : '2'}px) rotate(${showMobileHeaderMenu ? '45' : '0'}deg)`
                }} />
            </Div>

            {/* desktop links */}
            <Div d="flex"
                 bg={{ xs: 'bgColorPrimary', md: 'transparent' }}
                 align={{ xs: 'stretch', md: 'center' }}
                 flexDir={{ xs: 'column', md: 'row' }}
                 pos={{ xs: 'absolute', md: 'static' }}
                 p={{
                     t: { xs: "6rem", md: "0" },
                     b: { xs: "2rem", md: "0" },
                     x: { xs: "1.5rem", md: "0" },
                 }}
                 top="0"
                 left="0"
                 right="0"
                 zIndex={{ xs: "-1", md: "0" }}
                 shadow={{ xs: "4", md: "0" }}
                 opacity={{
                     xs: showMobileHeaderMenu ? "1" : "0",
                     md: "1",
                 }}
                 transform={{
                     xs: `translateY(${showMobileHeaderMenu ? "0" : "-100%"})`,
                     md: "none",
                 }}
                 transition
            >
                <AnchorLink to="/" text="HOME" />
                <AnchorLink to="/public" text="Acheter mon NFT" />
                <AnchorLink to="/kyc" text="KYC" />

                {account ? <WalletButton /> : <ConnectButton />}
            </Div>
        </>
    );
};

export default Links;