import React from 'react'
import { Div } from 'atomize'
import '../../styles.css'

const MiniLoader = ({ children, w = '10rem', ...props }) => {
    return (
        <Div d="flex" flexDir="column" align="center" w={w} {...props}>
            <Div id="dream-load"></Div>
        </Div>
    );
};

export default MiniLoader;