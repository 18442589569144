import { useCallback, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { BLOCK_EXPLORER } from 'config/constants'
import { Div, Icon, Anchor, Text } from 'atomize'

const types = {
    success: { bg: 'success700', iconName: 'Success' },
    error: { bg: 'danger700', iconName: 'CloseSolid' },
    info: { bg: 'info700', iconName: 'InfoSolid' },
    warning: { bg: 'warning700', iconName: 'AlertSolid' }
};

const StyledNotification = ({ innerRef, bg, top, zIndex, children, ...props }) => {
    return (
        <Div
            ref={innerRef}
            bg={bg}
            pos="fixed"
            w="100%"
            maxW={{ sm: "400px", xs: "calc(100% - 32px)"}}
            textColor="white"
            right="16px"
            rounded="lg"
            p="12px"
            top={`${top}px`}
            zIndex={zIndex}
            transition
            {...props}
        >
            {children}
        </Div>
    );
}

const Notification = ({ notification, onRemove, ttl, top, zIndex, ...props }) => {
    const timer = useRef(0);
    const ref = useRef(null)
    const removeHandler = useRef(onRemove)
    const { id, title, description, type, tx } = notification;

    const notificationType = types[type];
    const blockExplorer = BLOCK_EXPLORER[process.env.REACT_APP_CHAIN_ID];

    const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler]);

    const handleMouseEnter = () => {
        clearTimeout(timer.current)
    };

    const handleMouseLeave = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = window.setTimeout(() => {
            handleRemove();
        }, ttl);
    };

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = window.setTimeout(() => {
            handleRemove();
        }, ttl);

        return () => {
            clearTimeout(timer.current);
        }
    }, [timer, ttl, handleRemove]);

    return (
        <CSSTransition nodeRef={ref} timeout={250} {...props}>
            <StyledNotification innerRef={ref} bg={notificationType.bg} top={top} zIndex={zIndex} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Div d="flex" flexDir="row" align="center">
                    <Icon name={notificationType.iconName} color="white" size="20px" m={{ r: '1rem' }} />

                    <Div d="flex" flexDir="column">
                        <Div textWeight="800">{title}</Div>
                        {tx ?
                            <Anchor
                                href={`${blockExplorer}/tx/${description}`}
                                target="_blank"
                                textDecoration="underline"
                                textColor="white"
                                d="flex"
                                align="center"
                            >
                                <Icon name="External" color="white" size="18px" m={{ r: '0.25rem' }} />
                                <Text hoverTextColor="info400">View transaction on explorer</Text>
                            </Anchor>
                            :
                            <Div>{description}</Div>
                        }
                    </Div>
                </Div>
            </StyledNotification>
        </CSSTransition>
    );
}

export default Notification;