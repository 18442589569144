import { createContext, useCallback, useState } from 'react'

export const NotificationsContext = createContext(undefined);

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const notification = useCallback(({ title, description, tx, type }) => {
        setNotifications((prevNotifications) => {
            const id = title + Math.random();
            const currentNotifications = prevNotifications.filter((prevNotification) => prevNotification.id !== id);

            return [
                {
                    id,
                    title,
                    description,
                    tx,
                    type
                },
                ...currentNotifications
            ];
        });
    }, [setNotifications]);

    const notificationError = useCallback((title, description, tx = false) => {
        return notification({ title, description, tx, type: 'error' });
    }, [notification]);

    const notificationInfo = useCallback((title, description, tx = false) => {
        return notification({ title, description, tx, type: 'info' });
    }, [notification]);

    const notificationSuccess = useCallback((title, description, tx = false) => {
        return notification({ title, description, tx, type: 'success' });
    }, [notification]);

    const notificationWarning = useCallback((title, description, tx = false) => {
        return notification({ title, description, tx, type: 'warning' });
    }, [notification]);

    const clear = useCallback(() => setNotifications([]), []);
    const remove = useCallback((id) => {
        setNotifications((prevNotifications) => prevNotifications.filter((prevNotification) => prevNotification.id !== id));
    }, []);

    return <NotificationsContext.Provider value={{ notifications, clear, remove, notificationError, notificationInfo, notificationSuccess, notificationWarning }}>
        {children}
    </NotificationsContext.Provider>
};