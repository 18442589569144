import React from 'react'
import { NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Button } from 'atomize'
import { formatAddress } from 'utils/format'

const WalletButton = () => {
    const { account } = useWeb3React();

    return (
        //<NavLink to="/account">
            <Button
                d="flex"
                bg="lightColor"
                hoverBg="lightColor700"
                textColor="darkColor"
                textWeight="700"
            >
                {formatAddress(account)}
            </Button>
        //</NavLink>
    )
};

export default WalletButton;
