import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { Web3Provider } from '@ethersproject/providers'

const POLLING_INTERVAL = parseInt(process.env.REACT_APP_NODE_POLLING_INTERVAL);
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

export const injected = new InjectedConnector({ supportedChainIds: [chainId] });
const walletconnect = new WalletConnectConnector({
    infuraId: process.env.REACT_APP_INFURA_KEY,
    supportedChainIds: [1, 5],
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true
});

export const connectorsByName = {
    injected: injected,
    walletconnect: walletconnect
};

export const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = POLLING_INTERVAL;
    return library;
};